import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import NavigationDesktop from "./NavigationDesktop"
import NavigationMobile from "./NavigationMobile"
import CookieBar from "./CookieBar"

const Header = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      prismicSiteNavigation {
        data {
          body {
            ... on PrismicSiteNavigationDataBodyNavigationItem {
              primary {
                label {
                  text
                }
                target {
                  text
                }
                featured_title {
                  text
                }
                push_right
              }
              items {
                label {
                  text
                }
                target {
                  text
                }
                featured_image {
                  url
                  fluid {
                    ...GatsbyPrismicImageFluid
                  }
                }
                featured
                play_button
              }
            }
          }
        }
      }
    }
  `)

  const menuData = []

  data.prismicSiteNavigation.data.body.forEach((navItem, navIndex) => {
    menuData[navIndex] = {
      label: navItem.primary.label.text,
      target: navItem.primary.target.text,
      featured_title: navItem.primary.featured_title.text,
      push_right: navItem.primary.push_right,
      items: [],
      featured_items: [],
    }
    navItem.items.forEach((subItem, subIndex) => {
      if (subItem.featured) {
        menuData[navIndex].featured_items[subIndex] = {
          label: subItem.label.text,
          target: subItem.target.text,
          featured_image: subItem.featured_image.fluid,
          play_button: subItem.play_button,
        }
      } else {
        menuData[navIndex].items[subIndex] = {
          label: subItem.label.text,
          target: subItem.target.text,
        }
      }
    })
  })

  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
  }, [scrolled])

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  return (
    <header className="fixed top-0 w-full z-40">
      <div
        className={`flex bg-gold relative overflow-hidden translate-z-0 transition-all duration-500 ease-in-out ${
          scrolled ? "max-h-8 xl:max-h-0 py-2 xl:py-0" : "max-h-8 py-2"
        }`}
      >
        <div className="hidden xl:flex items-center h-full static top-0 xl:absolute xl:left-1/2 xl:transform xl:-translate-x-1/2 mx-auto">
          <img
            className="inline w-3 mr-2 align-middle"
            src={"/made-in-the-uk-icon.svg"}
            alt="Made in the UK"
          />
          <span className="text-tiny uppercase text-darkgrey tracking-widest align-middle font-[600]">
            Designed and manufactured in the UK
          </span>
        </div>
        <div className="flex flex-row items-center justify-between w-full">
          <ul className="list-none flex flex-row mx-auto md:ml-0 md:mr-0 text-darkgrey text-tiny uppercase tracking-wide xl:tracking-widest align-middle px-3 xl:px-14">
            <li>
              <Link
                className="px-3 border-r border-darkgrey hover:text-white duration-500 whitespace-nowrap font-[600]"
                to="/request-a-quote/"
              >
                Request a quote
              </Link>
            </li>
            <li>
              <Link
                className="px-3 border-r border-darkgrey hover:text-white duration-500 whitespace-nowrap font-[600]"
                to="/brochure-downloads/"
              >
                Brochures
              </Link>
            </li>
            <li>
              <Link
                className="px-3 hover:text-white duration-500 whitespace-nowrap font-[600]"
                to="/architects-downloads/"
              >
                Architects downloads
              </Link>
            </li>
          </ul>
          <a href="tel:+44 800 138 6688" className="text-darkgrey hover:text-white duration-500 font-body font-[600] text-xxs px-6 xl:px-[4.25rem] md:block hidden">
            <i
              className="fas fa-phone-alt mr-1 align-middle"
              aria-hidden
            ></i>
            +44 (0) 800 138 6688
          </a>
        </div>
      </div>
      <NavigationDesktop items={menuData} />
      <NavigationMobile items={menuData} />
    </header>
  )
}

export default Header
